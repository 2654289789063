<template>
  <div class="simple-page"></div>
</template>

<script>
export default {
  data() {
    return {}
  },

  methods: {},
}
</script>
